import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import Gallery from '../components/Gallery'
import Navbar from '../components/Navbar'
import PreviewCompatibleBackgroundImage from '../components/PreviewCompatibleBackgroundImage'
import { Link } from 'gatsby'

export default class IndexPage extends React.Component {
  render() {
    const { data } = this.props
    const { edges: posts } = data.allMarkdownRemark
    const firstpost = posts[0].node.frontmatter

    
    return (
      <Layout>
        <div className="homepage">
          <Navbar />
          <section className="first">
            <div className="blockOne">
              <PreviewCompatibleBackgroundImage imageInfo={firstpost.blockOne.image} />
              <div className="container">
                <div className="row">
                  <div className="col-md-7">
                    <h1>{firstpost.blockOne.title}</h1>
                    <p>{firstpost.blockOne.description}</p>
                    <Link to="/contact" className="btn-secondary" title="Logo">Contactez-nous</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="blockTwo">
              <div className="container">
                <div className="row">
                  <div className="col-md-6">
                    <h3>{firstpost.blockTwo.title}</h3>
                    <p>{firstpost.blockTwo.description}</p>
                  </div>
                </div>
                <PreviewCompatibleBackgroundImage imageInfo={firstpost.blockTwo.image} />
              </div>
            </div>
          </section>

          <section>
            <div className="blockThree">
              <div className="container">
                <div className="row">
                  <div className="col-md-3">
                    <h3>{firstpost.blockThree.title}</h3>
                    <p>{firstpost.blockThree.description}</p>
                  </div>
                  <div className="col-md-7 offset-md-2">
                    <PreviewCompatibleBackgroundImage imageInfo={firstpost.blockThree.image} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div className="blockFour">
                    <PreviewCompatibleBackgroundImage imageInfo={firstpost.blockFour.image} />
                    <h3>{firstpost.blockFour.title}</h3>
                    <p>{firstpost.blockFour.description}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="blockFive">
                    <PreviewCompatibleBackgroundImage imageInfo={firstpost.blockFive.image} />
                    <h3>{firstpost.blockFive.title}</h3>
                    <p>{firstpost.blockFive.description}</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          
          <section>
            <div className="container">
              <Gallery gridItems={firstpost.gallery.blurbs} />
            </div>
          </section>

        </div>
      </Layout>
    )
  }
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
}

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "home-page" } }}
    ) {
      edges {
        node {
          id
          frontmatter {
            blockOne {
              title
              description
              image
            }
            blockTwo {
              title
              description
              image
            }
            blockThree {
              title
              description
              image
            }
            blockFour {
              title
              description
              image
            }
            blockFive {
              title
              description
              image
            }
            gallery {
              blurbs {
                image
                text
              }
            }
          }
        }
      }
    }
  }
`
